import React from "react"
import { NavLink } from "react-router-dom"
import { SocialIcon } from "react-social-icons"

const class_link_active = "menulink-active"
const class_link = "menulink"

export default class Footer extends React.Component {

    constructor(props) {


        super(props);

        this.ref_navimprint = React.createRef(null);
        this.ref_navdatap = React.createRef(null);

        this.props = props;



    }

    componentDidMount() {


    }


    render() {


        return (

            // <SocialIcon url="" className="mr-4" target="_blank" fgColor="#fff" />

            <div className='footer'>
                <div className="footerleft">
                <NavLink
                    ref={this.ref_navimprint}
                    // className="inflex-flex items-center py-3"
                    to="/imprint"
                    className={(navData) => navData.isActive ? class_link_active : class_link}
                > Impressum</NavLink>
                <NavLink
                    ref={this.ref_navdatap}
                    // className="inflex-flex items-center py-3"
                    to="/datasafety"
                    className={(navData) => navData.isActive ? class_link_active : class_link}
                > Datenschutz</NavLink>

                 </div>
                <div className="footerright"><SocialIcon url="https://www.instagram.com/diehess.jewels" className="border rounded-2xl border-black impressumsocialicon" target="_blank" bgColor="#ffffff" fgColor="#000000" /></div>
            </div>
        )
    }
}
