import configData from "../configData.json";
import React, { Component } from "react"
import { Link } from "react-router-dom"
//import CounterTest from "./CounterTest.js";
import ConfDialogue from "./ConfDialogue.js";
import Draggable from "./Draggable.js";

import { CgAdd, CgRemove, CgPen } from "react-icons/cg"; // https://react-icons.github.io/react-icons/icons?name=cg

import InfoPage from "./InfoPage"

class Posts extends React.Component {

    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element

        this.props = props

        this.ref_posts = React.createRef(null)

        this._updatedData = null

        if (window.location.href.includes("localhost") || window.location.href.includes("jsm3.routerich.com")) {
          configData.images_url = configData.images_url_dev
        } else {
          //configData.images_url = configData.images_url
          if (window.location.href.includes("diejasmin.de")) {
            configData.images_url = configData.images_url2
          }
        }
    }

    // callback function that looks for function to be called and calles it
    updatedData() {

        if (this._updatedData) {
            console.log("updated data")
            this._updatedData.call().call()

        }
        this._updatedData = null
    }


    componentDidMount() {

        this.props.onNavUpdate("/")
        //this.updateData()
    }


    // DeletePost = (postid) => {

    //     console.log("Maintain ::: Delete : " + postid)

    //     this._updatedData = (() => () => {
    //         console.log("callback: actually delete Post")

    //         this.updatePosts(
    //             this.state.data.filter(
    //                 (post) => post.id != postid
    //             )
    //         );
    //     })

    //     this.updateData()
    // }

    async updatePosts(thedata) {

        //const res = await dbPost(thedata)

        // this.setState({ data: thedata });
        // this.filterPosts();
        // console.log("MAIN ::: posts updated.")

        // const theparams = JSON.stringify(thedata)

        // const encodedString = theparams.toString('base64');
        // //console.log("encodedString")
        // //console.log(configData.script_url + "?json_content=" + encodeURIComponent(theparams))
        // fetch(configData.script_url + "?json_content=" + encodeURIComponent(theparams), {
        //     method: "GET",
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     //body: JSON.stringify(thedata)
        // })
        //     .then(
        //         (response) => {
        //             //alert(response.json()    )
        //             //console.log("data")
        //             if (response.status == 200) {
        //                 // Process the response and update the view.
        //                 // Recreate a setTimeout API call which will be fired after 1 second.
        //                 console.log("db update ok.")
        //             } else {
        //                 clearTimeout(this.apiTimeout);
        //                 alert("no update could be sent.")
        //             }

        //             const thedata2 = response.json()
        //             //console.log(thedata2)
        //             return thedata2
        //         }, (error) => {
        //             if (error) {
        //                 // handle error here
        //                 alert(error)
        //                 //console.log("ERROR")
        //                 //console.log(error)
        //             }
        //         }
        //     )
        //     .then((data) => {
        //         console.log(data)
        //         this.setState({ data: thedata });
        //         this.filterPosts();
        //         console.log("MAIN ::: posts updated.")
        //     });


    }

    getThumb(t) {


        let a = t.split('.')
        let ext = a.pop()
        return t + "_thumb." + ext
    }


    render() {

        console.log("POSTS RENDER MAIN START")

        if (!this.props.thedata) return <InfoPage> Inhalte werden geladen ...</InfoPage>;

        //console.log(tagskeys)
        console.log("DISPLAY Posts")
        return (

            <div className="posts">
                {
                        (this.props.state_admin) ? (
                            <Link to="/post/create"><CgAdd size="35" /></Link>
                        ) : ""
                    }
                <section className="posts-content">

                    {

                        (
                            (this.props.thedata.data_filtered.length > 0)
                                ?
                                <div ref={this.ref_posts} className="grid gap-x-0 gap-y-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-auto jmx-auto">
                                    { /* grid md:grid-cols-2 lg:grid-cols-3 gap-1 px-auto justify-self-center text-center mx-auto w-full */}

                                    {
                                    (this.props.state_admin) ? (

                                      this.props.thedata.data_filtered.map((post, index) => (
                                        <Draggable key={post.id} akey={post.id} className="mx-auto post-entry" ref_posts={this.ref_posts} onAction={this.props.onAction.bind(this)}>
                                            <div className="adm-edit">
                                                  <div className="inline-flex" >
                                                      <ConfDialogue
                                                          text={post.name + " wirklich löschen?"}
                                                          action={post.id}
                                                          onConfirm={(event) => this.props.onAction("delete", post.id)}
                                                      ><CgRemove size="35" className="cursor-pointer" />

                                                      </ConfDialogue>
                                                  </div>
                                                  <div className="inline-flex" >
                                                      <Link to={"/post/edit/" + post.id}><CgPen size="35" /></Link>
                                                  </div>
                                              </div>
                                            <div className="imagehoster" key={post.id}>
                                            <Link to={"/post/" + post.id} >
                                                    <img src={(post.files.length) ? configData.images_url + "/" +  this.getThumb(post.files[0]) : ""} alt={post.name} className="posts-image" />
                                            </Link>
                                            </div>
                                        </Draggable>
                                      ))

                                    ) : (
                                      this.props.thedata.data_filtered.map((post, index) => (
                                        <div key={post.id} className="mx-auto post-entry" >
                                            <div className="imagehoster" key={post.id}>
                                            <Link to={"/post/" + post.id} >
                                                    <img src={(post.files.length) ? configData.images_url + "/" +  this.getThumb(post.files[0]) : ""} alt={post.name} className="posts-image" />
                                            </Link>
                                            </div>
                                        </div>
                                      ))
                                    )
                                    }



                                </div>
                                : < div className='warning-big'> Die Filter-Kombination hat kein Ergebnis ergeben. <a>Filter zurücksetzen</a></div>
                        )

                    }
                </section>

            </div >
        )

    }
}

export default Posts;
