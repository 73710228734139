import React from "react"
import { NavLink } from "react-router-dom"
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import navbar from "../navbar.css";
import filter from "../filter.css";
import filterimg from '../img/filter.svg';
import closeimg from '../img/close.svg';
import arrowleft from '../img/arrow-left.svg';
import { useNavigate } from 'react-router-dom';

const class_link_active = "menulink-active" //"font-700 inflex-flex items-center py-3 px-3 text-blue-800 hover:text-green-800 text-2xl font-bold cursive underline mt-2"
const class_link = "menulink" //"font-700 inflex-flex items-center py-3 px-3 text-blue-800 hover:text-green-800 text-2xl font-bold cursive tracking-widest"
const class_imglink_active = "imglink-active" //"font-700 inflex-flex items-center py-3 px-3 text-blue-800 hover:text-green-800 text-2xl font-bold cursive underline mt-2"
const class_imglink = "imglink" //"font-700 inflex-flex items-center py-3 px-3 text-blue-800 hover:text-green-800 text-2xl font-bold cursive tracking-widest"


class Navbar extends React.Component {

    constructor(props) {
        super(props)

        this.menubarRef = React.createRef(null);
        this.logoRef = React.createRef(null);
        this.filterRef = React.createRef(null);
        this.containerRef = React.createRef(null);
        this.ref_filtermenu = React.createRef(null);
        this.ref_filtermenu_bottom = React.createRef(null);
        this.ref_filtercats = React.createRef(null);
        this.nodeRef_logo = React.createRef(null);
        this.ref_navabout = React.createRef(null);

        this.props = props;

        //const history = useNavigation()
        //this.handleScroll.bind(this)

        this.state = {
            gx_filterdisplay: false,
            gx_filtercatdisplay: "",
            gx_scrolling: false,
            gx_logobig: true,
        };
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {

        window.addEventListener('scroll', this.handleScroll);

        if (this.filterRef.current)
            this.filterRef.current.classList.add('menu-invisible')

    }

    componentDidUpdate() {

        //console.log(this.ref_filtermenu.current.classList)


        if (this.filterRef.current && !this.filterRef.current.classList.contains("menu-invisible") && !this.filterRef.current.classList.contains("menu-visible"))
            this.filterRef.current.classList.add('menu-invisible')


        if (this.filterRef.current) {
            if (this.filterRef.current.classList.contains("menu-visible") && !this.state.gx_filterdisplay) {
                this.filterRef.current.classList.remove('menu-visible')
                this.filterRef.current.classList.add('menu-invisible')
            }
            if (this.filterRef.current.classList.contains("menu-invisible") && this.state.gx_filterdisplay) {
                this.filterRef.current.classList.remove('menu-invisible')
                this.filterRef.current.classList.add('menu-visible')
            }
        }

    }

    handleScroll = () => {
        var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // var menu_offset = this.props.ref_content.current.getBoundingClientRect().y
        // var menu_height = this.props.ref_header.current.getBoundingClientRect().height

        // //console.log(menu_offset)
        // //console.log(menu_height)
        // //console.log("--")

        // if ((menu_height - menu_offset) > 50 && this.state.gx_logobig) {
        //     this.logoRef.current.classList.remove('author')
        //     this.logoRef.current.classList.add('author-small')
        //     this.setState({ gx_logobig: false })
        // }
        // if ((menu_height - menu_offset) < 1 && !this.state.gx_logobig) {
        //     this.logoRef.current.classList.remove('author-small')
        //     this.logoRef.current.classList.add('author')
        //     this.setState({ gx_logobig: true })
        // }

        this.filterHide()

        if (bodyScrollTop > 10 && this.state.gx_logobig) {
            this.logoRef.current.classList.remove('author-big')
            this.logoRef.current.classList.add('author-small')
            if (this.filterRef.current) {
                this.filterRef.current.classList.remove('filter-big')
                this.filterRef.current.classList.add('filter-small')
            }
            this.setState({ gx_logobig: false })
        }
        if (bodyScrollTop < 10 && !this.state.gx_logobig) {
            this.logoRef.current.classList.remove('author-small')
            this.logoRef.current.classList.add('author-big')
            if (this.filterRef.current) {
                this.filterRef.current.classList.remove('filter-small')
                this.filterRef.current.classList.add('filter-big')
            }
            this.setState({ gx_logobig: true })

        }


    }

    // handleScroll = () => {
    //     var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    //     console.log(bodyScrollTop + " / " + this.state.gx_scrolling)
    //     console.log(this.containerRef.current)
    //     console.log(this.containerRef.current.offsetHeight)
    //     console.log(this.containerRef.current.getBoundingClientRect())


    //     //console.log(this)
    //     if (!this.state.gx_scrolling) {
    //         this.setState({ gx_scrolling: bodyScrollTop })
    //         if (bodyScrollTop && bodyScrollTop > 200) {
    //             this.logoRef.current.classList.add('author-small')
    //             this.logoRef.current.classList.remove('author')
    //             this.setState({ gx_logobig: false })
    //         }
    //     } else {

    //         if (bodyScrollTop - 50 > this.state.gx_scrolling && !this.state.gx_logobig) {
    //             this.setState({ gx_scrolling: bodyScrollTop })
    //         }
    //         if (bodyScrollTop + 50 < this.state.gx_scrolling && this.state.gx_logobig) {
    //             this.setState({ gx_scrolling: bodyScrollTop })
    //         }
    //         if (bodyScrollTop - 200 > this.state.gx_scrolling && this.state.gx_logobig) {
    //             this.setState({ gx_scrolling: bodyScrollTop })
    //             this.setState({ gx_logobig: false })
    //             this.logoRef.current.classList.remove('author')
    //             this.logoRef.current.classList.add('author-small')
    //         }
    //         if (bodyScrollTop + 200 < this.state.gx_scrolling && !this.state.gx_logobig) {
    //             this.setState({ gx_scrolling: bodyScrollTop })
    //             this.setState({ gx_logobig: true })
    //             this.logoRef.current.classList.add('author')
    //             this.logoRef.current.classList.remove('author-small')
    //         }
    //     }
    // }

    toggleFilter = () => {

        this.setState(prevstate => ({
            gx_filterdisplay: !prevstate.gx_filterdisplay,
        }), () => {
//            console.log("height update")
            //this.filterRef.current.style.height = this.ref_filtermenu.current.clientHeight+"px"
        });
    };

    toggleAdmin() {

    }


    filterHide () {

        if (this.ref_filtercats.current) {
            let childr = this.ref_filtercats.current.children

            for (let c of childr) {

                c.classList.remove("filter-entry-visible")


            }

            this.filterRef.current.style.height = "2.2rem"
                this.ref_filtermenu_bottom.current.style.top = "0"
                this.setState({
                    gx_filtercatdisplay: ""
                })
        }
    }
    filterExpand(e, key) {


        console.log("++++++++++++++++++++++")
        console.log(key)

        this.setState(prevstate => ({
            gx_filtercatdisplay: key,
        }), () => {

            let temp = e.target.parentElement.classList.contains("filter-entry-visible")

            this.filterHide()

            if (!temp) {
                e.target.parentElement.classList.add("filter-entry-visible")

                //this.ref_filtermenu.current.style = heightadapt;
                this.filterRef.current.style.height = e.target.nextSibling.clientHeight + this.ref_filtermenu.current.clientHeight + "px"
                this.ref_filtermenu_bottom.current.style.top = "" + (e.target.nextSibling.clientHeight) + "px"
            } else {
                this.filterRef.current.style.height = "2.2rem"
                this.ref_filtermenu_bottom.current.style.top = "0"
                this.setState({
                    gx_filtercatdisplay: ""
                })
            }


            //this.filterRef.current.setHeight (600)




        });

        //e.target.parentElement.classList.contains

    }

    render() {
        
        let tagskeys = [];
        if (this.props.thedata) {
            tagskeys = Object.keys(this.props.thedata.tagsar);
        }


        // https://blog.bitsrc.io/how-to-implement-smooth-transitions-in-react-bd0497b06b8
        return (
            <div className="site-header"
            //onMouseMove={console.log("move")}
            //onMouseOut={console.log("out")}
            //onMouseLeave={console.log("leave")}
            >
                <nav className="navbar">

                    {
                        (this.props.currentLocation != "/about" || true) ? (
                            <NavLink
                                ref={this.ref_navabout}                                
                                // className="inflex-flex items-center py-3"
                                to="/about"
                                className={(navData) => navData.isActive ? class_link_active : class_link}
                            > &Uuml;ber</NavLink>

                        ) : ""
                    }
                    {
                        (this.props.currentLocation != "/") ? (
                             <NavLink
                                ref={this.ref_navabouta}                                
                        // className="inflex-flex items-center py-3"                               
                                to={'/'}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.navigate(-1); // uses function look at export                                  
                                }}
                                className={(navData) => navData.isActive ? class_imglink_active : class_imglink}
                            > <img src={arrowleft} alt="Zur&uuml;ck" /></NavLink> 
                                 
                        ) : ""
                    }


                    {
                    (window.location.href.includes("localhost") || window.location.href.includes("jsm3.routerich.com")) ? (
                      <div><a href='#' className="" onClick={this.props.onAdminToggle}>Admin</a></div>
                    ) : ""
                    }

                    {/* <NavLink to="/Posts" className = {(navData) => navData.isActive ? class_link_active: class_link}                    > Posts</NavLink> */}


                </nav>
                <h1 ref={this.logoRef} className="author-std">Jasmin Hess</h1>

                {
                    (this.props.currentLocation == "/") ? (
                        <div className='btn-container'>
                            <button className="btn-filter" onClick={this.toggleFilter}>
                                {

                                    (this.state.gx_filterdisplay) ? (
                                        <img src={closeimg} alt='Close Filter' />
                                    ) : (
                                        <img src={filterimg} alt='Filter' />

                                    )
                                }
                            </button>
                        </div>) : ""
                }

                {
                    (this.props.currentLocation == "/") ? (
                        <div ref={this.filterRef} className="filter menu-invisible">
                            <div className="filter-wrapper" ref={this.ref_filtermenu}>
                                <div className='filter-wrapper-top'>
                                    <div className="filter-left">Filter</div>
                                    {
                                        ( //this.state.gx_filterdisplay &&


                                            <div className="menu menu-std" ref={this.ref_filtercats}>


                                                {
                                                    tagskeys.length && tagskeys.map(key => {

                                                        return (
                                                            <div key={key} className="filter-entry">
                                                                <div className="cursor-pointer" onClick={(event) => this.filterExpand(event, key)}>{key}</div>
                                                                <ul className="list">
                                                                    {
                                                                        this.props.thedata.tagsar[key].map((val, ind) => {
                                                                            return <li key={ind} className="list-entry"><a href='#' className={(this.props.filters.includes(key + ":" + val)) ? "font-bold" : ""} key={ind} onClick={(event) => this.props.onFilter(event, key + ":" + val)}>{val}</a></li>
                                                                        })
                                                                    }



                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                }



                                            </div>


                                        )}
                                    <div className='filter-right'></div>
                                </div>
                                <div className="filter-wrapper-bottom" ref={this.ref_filtermenu_bottom}>
                                    {
                                        this.props.filters && this.props.filters.length ? (
                                        this.props.filters.map((val, ind) => {
                                            return <span className="filterslist" key={ind}><a href='#' onClick={(event) => this.props.onFilter(event, val)}>{val}</a></span>
                                        })
                                        ) : <span className="filterslist">Keine Filter gesetzt.</span>
                                    }
                                </div>
                            </div>
                        </div>

                    ) : ""
                }




            </div>

        )
    }

}

// this function is initializing Navbar and giving navigate as a prop to Navbar
function WithNavigate(props) {
    let navigate = useNavigate();
    return <Navbar {...props} navigate={navigate} />
}

export default WithNavigate
