import React from "react"
import { config } from "react-transition-group";
import samplepic from "../samplepics/pineapple.jpg"
import InfoPage from "./InfoPage"

const reactStringReplace = require('react-string-replace');

export default class About extends React.Component {

    constructor(props) {

        super(props);

        this.nl2br = require('react-nl2br');

        this.props = props;

    }

    componentDidMount() {

        this.props.onNavUpdate("/about")
    }

    cformat (str) {

      //var find = "a"
      //var replace = "b"
      //str.replace('string-to-replace', '<span class="something"></span>')
      //str =  str.split(find).flatMap((item) => [item, replace]).slice(0, -1);

      str = reactStringReplace(str, 'DIEHESS_BOLD', (match, i) => (
         <span>DIEHESS</span>
      ));

      return str
    }



    render() {


        if (!this.props.thedata) return <InfoPage> Inhalte werden geladen ...</InfoPage>;

        return (

            <div>
                <div className="aboutbox float-left">
                    <img src={this.props.imgurl + "/" + this.props.thedata.data.aboutdata.img} alt="Jasmin Hess" className=" float-left  mr-8 mb-6 aboutimage" />
                    {this.cformat(this.nl2br(this.props.thedata.data.aboutdata.content))}
                </div>
                <div className="aboutcv clear-both">

                    {
                        this.props.thedata.data.aboutdata.entries.map((entry, index) => (
                            <div className="aboutentry" key={index}>
                                <div className="aboutdate">
                                    {entry.date}
                                </div>
                                <div className="aboutdescription">
                                    {this.cformat(this.nl2br(entry.description))}
                                </div>
                            </div>

                        )
                        )
                    }
                </div>
            </div>
        )
    }
}
