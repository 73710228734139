import React from "react"
import InfoPage from "./InfoPage";
import { useNavigate } from "react-router-dom"
import ImageGallery from 'react-image-gallery'; // https://www.npmjs.com/package/react-image-gallery
import UploadFiles from "./UploadFiles.js";
import ReactTags from 'react-tag-autocomplete'

import arrowleft from '../img/arrow-left.svg';
import {FaThumbtack, FaTimes} from 'react-icons/fa';

// Wrap and export to have navigation in props
export default function (props) {
    const navigation = useNavigate();

    return <Post {...props} navigation={navigation} />;
}


class Post extends React.Component {

    constructor(props) {

        super(props)

        this.props = props
        this.nl2br = require('react-nl2br');

        this.postdata = null;

        this.ref_gallery = React.createRef(null)
        this.ref_article = React.createRef()

        this.state = {
            post: {
                id: false,
                name: "",
                order: 99,
                subtitle: "",
                description: "",
                date: "",
                files: [],
                tags: [
                ],
                category: ""
            },

            isEdit: false,
            isPreview: false,
            isNew: false,
            isModified: false,
        };

        //console.log(this.props.thedata.tags)
        //     this.tagsuggestions = tags.map((c,i) => {
        //         return { name: c}
        //   })
    }


    addImage(imagename) {
        console.log("IMAGE LOGGED")

        let a = this.state.post.files.slice()
        console.log("IMAGE LOGGED")

        this.state.isModified = 1


        if (imagename in a) {
            console.log("IMAGE ALREADY PRESENT.")
        } else {
            a.push(imagename);
        }

        // const elementsIndex = db.findIndex(element => element.id == this.props.theid)

        this.setState({
            post: {
                ...this.state.post,
                files: a
            }
        })
    }

    updatePostDataFromDb() {

        this.postdata = this.getPost(this.props.theid)

        if (this.postdata && this.postdata.length) {
            this.setState({
                post: {
                    id: this.postdata[0].id,
                    name: this.postdata[0].name,
                    order: this.postdata[0].order,
                    subtitle: this.postdata[0].subtitle,
                    files: this.postdata[0].files,
                    tags: this.postdata[0].tags,
                    date: this.postdata[0].date,
                    description: this.postdata[0].description,
                }
            })
        }
    }

    componentDidMount() {

        this.props.onNavUpdate("/post")

        if (this.props.state_admin && (!this.state.isEdit && !this.state.isPreview)) {
            this.setState({ isPreview: true, isEdit: true })
        }
        if (this.state.isNew != this.props.isnew)
            this.setState({ isNew: this.props.isnew })

        console.log("+++++++++")
        console.log(this.props.edit)
        if (this.state.isEdit != this.props.edit)
            this.setState({ isEdit: this.props.edit, isPreview: false })

        if (!this.state.isNew && !this.state.isEdit)
            this.updatePostDataFromDb()

    }

    componentDidUpdate() {
        
        if (this.ref_article.current) {
            console.log("TRIGGER INIT SCROLL")
            
            let pos = this.ref_article.current.style.position;
            let top = this.ref_article.current.style.top;
            this.ref_article.current.style.position = 'relative';
            this.ref_article.current.style.top = '-5em';
            this.ref_article.current.scrollIntoView({behavior: 'smooth', block: 'start'});
            this.ref_article.current.style.top = top;
            this.ref_article.current.style.position = pos;
                        
        }
        //console.log(this.ref_gallery.current)
    }


    // return data for a single post
    getPost(theid) {

        let datatmp = this.props.thedata.data

        console.log(datatmp)

        if (datatmp) {
            datatmp = datatmp.postdata.filter((post) => post.id == theid)
            console.log("POST DATA IS AS FOLLOWS")
            console.log(datatmp)
            return datatmp
        }
        return false
    }

    submit() {
        console.log("DO SUBMIT")
        // TODO UPDATE DATA BEFORE POST

        var thepost = this.state.post
        const current = new Date();
        const date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;

        if (this.state.isNew) {

            const newid = thepost.name.replace(/\s/g, '_') + "_" + Math.round(Math.random() * 10000)
            thepost.id = newid
        }

        thepost.date = date

        this.props.onAction("createormodify", { isnew: this.state.isNew, data: thepost })

        if (this.state.isNew) {
            //const navigate = useNavigate();
            this.props.navigation("/post/" + thepost.id);
        }

        this.state.isModified = 0
    }

    preview() {

        this.setState({
            isPreview: !this.state.isPreview
        })
    }

    dataUpdateForm(e, name) {
        console.log(e.target.name)

        this.state.isModified = 1

        if (e.target.name == 'title')
            this.setState({
                post: {
                    ...this.state.post,
                    name: e.target.value
                }
            })
        if (e.target.name == 'subtitle')
            this.setState({
                post: {
                    ...this.state.post,
                    subtitle: e.target.value
                }
            })

        if (e.target.name == 'description')
            this.setState({
                post: {
                    ...this.state.post,
                    description: e.target.value
                }
            })

    }


    onTagDelete(e) {

        let thetags = this.state.post.tags

        this.state.isModified = 1

        thetags.splice(e, 1);

        this.setState({
            post: {
                ...this.state.post,
                tags: thetags
            }
        })
    }

    onTagAddition(e) {

        this.state.isModified = 1

        let thetags = this.state.post.tags
        let checkindex = thetags.findIndex(element => element.name == e.name)
        if (checkindex < 1) {

            thetags = [...thetags, {
                name: e.name
            }]

            this.setState({
                post: {
                    ...this.state.post,
                    tags: thetags
                }
            })
        }
    }

    customAction(e, a) {
        e.preventDefault()

        console.log(this.ref_gallery.current.state)

        if (a == "left")
            this.ref_gallery.current.slideLeft()
        if (a == "right")
            this.ref_gallery.current.slideRight()
        if (a == "full")
            this.ref_gallery.current.toggleFullScreen()
        //
        //          console.log(this.ref_gallery.current.state.currentIndex)
        console.log(this.ref_gallery.current)

        console.log(this.ref_gallery.current)
        //    alert(this.ref_gallery.current.state.currentIndex)

    }
    _renderCustomControls() {
        return (
            <div className="post-gallery">
                <div className="div-align-left">
                    <a href='' className='' onClick={e => this.customAction(e, "left")}>
                        <img src={arrowleft} alt='' />
                    </a>
                </div>
                <div className="div-align-right">
                    <a href='' className='' onClick={e => this.customAction(e, "right")}>
                        <img src={arrowleft} alt='' className='img-rotate180' />
                    </a>
                </div>
            </div>
        )
    }
    _galleryOnClick() {

        console.log("GALLERY CLICK")
        this.ref_gallery.current.toggleFullScreen()
    }

    image_primary (e) {

        this.state.isModified = 1
        e.preventDefault()

        let idx = this.ref_gallery.current.state.currentIndex
        let temp = this.state.post.files
        let o = temp[idx]

        temp.splice(idx,1)
        temp.splice(0,0,o)

        this.setState({files : temp})
    }

    image_delete (e) {
        e.preventDefault()

        this.state.isModified = 1

        let idx = this.ref_gallery.current.state.currentIndex
        let temp = this.state.post.files
        temp.splice(idx,1)

        this.setState({files : temp})
    }

    // RENDER
    render() {

        console.log("Render single post.")

        if (!this.postdata && !this.state.isNew) return <InfoPage>Inhalt wird geladen ...</InfoPage>;
        if (!this.postdata.length && !this.state.isNew) return <InfoPage>Dieser Inhalt existiert nicht (mehr) ... <a href='/'>Zum Anfang</a></InfoPage>;


        let images = []
        for (let f of this.state.post.files) {

            let a = f.split('.')
            let ext = a.pop()

            images.push(
                {
                    fullscreen: this.props.imgurl + "/" + f,
                    original: this.props.imgurl + "/" + f + "_thumb."+ext,
                    thumbnail: this.props.imgurl + "/" + f + "_thumb."+ext,
                    originalAlt: this.state.post.name
                })
        }

        return (

            <main className="post" ref={this.ref_article}>
                <article className="post-article grid lg:grid-cols-2 md:grid-cols-2 sd:grid-cols-2 gap-10 mx-10">
                    <div className="post-image">
                        <div className="post-image-gallery">
                        {images.length ?
                            <ImageGallery
                                showNav={false}
                                showFullscreenButton={false}
                                ref={this.ref_gallery}
                                items={images}
                                onClick={this._galleryOnClick.bind(this)}
                                showPlayButton={false}
                                autoPlay={false}
                                slideOnThumbnailOver={true}
                                thumbnailPosition={"left"}
                                //renderCustomControls={this._renderCustomControls.bind(this)}
                            />
                            : ""}
                        </div>
                        {(this.props.state_admin && !this.state.isPreview) ? (

                            <div>
                                <div className='img-control grid grid-cols-2'>
                                    <div><a href='' onClick={this.image_primary.bind(this)}><FaThumbtack size="38" /></a></div>
                                    <div><a href='' onClick={this.image_delete.bind(this)}><FaTimes size="38" /></a></div>

                                </div>
                                <UploadFiles className="upload-area" onUpload={this.addImage.bind(this)} />
                            </div>




                        ) : ""
                        }

                        {(this.props.state_admin && !this.state.isPreview) ? (
                            <div>
                                <hr className="hr-division"></hr>
                                <ReactTags
                                    //ref={reactTags}
                                    classNames={
                                        {
                                            root: 'm-2 p-2',
                                            rootFocused: 'my-2',
                                            selected: 'bg-gray-200 rounded mx-2',
                                            selectedTag: 'bg-blue-300 m-2 rounded p-2',
                                            selectedTagName: 'font-bold',
                                            search: 'm-2 p-2 bg-gray-100 rounded',
                                            searchWrapper: 'py-2',
                                            searchInput: 'p-0 rounded',
                                            suggestions: 'cursor-pointer p-2 m-2 bg-gray-100 rounded',
                                            suggestionActive: 'is-active bg-blue cursor-pointer',
                                            suggestionDisabled: 'is-disabled text-bold',
                                            suggestionPrefix: 'font-bold'
                                        }
                                    }
                                    tags={this.state.post.tags}
                                    allowNew={true}
                                    suggestions={this.props.thedata.tags}
                                    onDelete={this.onTagDelete.bind(this)}
                                    onAddition={this.onTagAddition.bind(this)}
                                />

                            </div>
                        ) : ""
                        }

                        {(this.props.state_admin) ? (
                            <div>
                                <hr className="hr-division"></hr>
                                <div className="post-preview">
                                    <input className="" type='button' name='preview' value={this.state.isPreview ? "Editieren" : "Vorschau"} onClick={this.preview.bind(this)}></input>
                                </div>

                                <div className={(this.state.isModified) ? "post-save" : "post-saved"}>
                                    <input className='' type='button' name='save' value={!this.state.isNew == 1 ? "Speichern" : "Veröffentlichen"} onClick={this.submit.bind(this)}></input>
                                </div>
                            </div>
                        ) : ""
                        }
                    </div>
                    <div className="post-main">

                        {
                            (this.props.state_admin && this.state.isEdit && !this.state.isPreview) ? (
                                <div className='post-title'><input className="inp-post-title" type="text" name="title" placeholder="Titel" value={this.state.post.name} onChange={this.dataUpdateForm.bind(this)} /></div>
                            ) : <h1 className="post-title">{this.state.post.name}</h1>
                        }
                        {
                            (this.props.state_admin && this.state.isEdit && !this.state.isPreview) ? (
                                <div className='post-subtitle'><input className="inp-post-subtitle" type="text" name="subtitle" placeholder="Untertitel" value={this.state.post.subtitle} onChange={this.dataUpdateForm.bind(this)} /></div>
                            ) : <h2 className="post-subtitle">{this.state.post.subtitle}</h2>
                        }

                        {
                            (this.props.state_admin && this.state.isEdit && !this.state.isPreview) ? (
                                <div className='post-description'><textarea className="inp-post-description" name="description" placeholder="Beschreibung..." value={this.state.post.description} onChange={this.dataUpdateForm.bind(this)} /></div>
                            ) : <div className="post-description">
                                <p className="">
                                    {this.nl2br(this.state.post.description)}
                                </p>
                            </div>
                        }


                    </div>
                </article>
            </main>

        )
    }
}
