import React from "react"
import { config } from "react-transition-group";
import InfoPage from "./InfoPage"

export default class Admin extends React.Component {

    constructor(props) {

        super(props);

        this.props = props;

    }

    componentDidMount() {

        this.props.onNavUpdate("/admin")
    }


    login () {
        console.log("login")
        this.props.onAction ("login", "true")
    }

    render() {

//        if (!this.props.thedata) return <InfoPage> Inhalte werden geladen ...</InfoPage>;

        return (

            <div>
                <div className="aboutbox">
                    <input className="post-save cursor-pointer" onClick={this.login.bind(this)} />
                </div>
                
            </div>
        )
    }
}