
import React, { Children } from "react";

export default class TheDb extends React.Component {

    constructor(props) {

        super(props)

        this.props = props
        this.state = { data: false, data_filtered: false, tagsar: [] };
        this.filters = this.props.filters
    }

    componentDidMount() {
        this.updateData()
    }

    componentWillUnmount() {

        clearTimeout(this.apiTimeout)
    }

    componentDidUpdate(prevProps) {

        //this.sortPosts(this.state.data)

        if (prevProps.filters !== this.props.filters) {
            this.filterPosts(this.state.data.postdata);
        }

        if (prevProps.actions !== this.props.actions) {

            console.log("perform actions:")
            if (this.props.actions.length)
                this.actions_perform(this.props.actions)
        }

    }

    async actions_perform(actions) {

        console.log(actions)
        // To have current data stack
        let datatmp = await this.dbGet()
        let doupdate = 0
        let doupdate_dbonly = 0

        for (var i = 0; i < actions.length; i++) {

            switch (actions[i].action) {
                case "delete":
                    console.log("delete triggered: " + actions[i].value)

                    datatmp.postdata = datatmp.postdata.filter(
                        (post) => post.id != actions[i].value
                    )

                    doupdate = 1
                    break;
                case "createormodify":

                    datatmp.postdata = datatmp.postdata.filter(
                        (post) => post.id != actions[i].value.data.id
                    )

                    datatmp.postdata.push(actions[i].value.data)

                    doupdate = 1
                    break;
                case "login":
                    if (actions[i].value == "true") {
                        let datatmp = await this.dbGetLogin()
                        console.log("RESRESRESRESRESRESRES")
                        console.log(datatmp)
                        if (datatmp) {
                            if (datatmp.l == "1") {
                                this.props.onLogin(true)
                            }
                        }
                    }
                    break;

                case "ordermodify":

                  console.log("GOING TO MODIFY tHEe order ")

                  //console.log(actions[i].value)
                  //console.log(datatmp.postdata)

                  let ind_target = 0
                  let ind_who = 0
                  for (var j = 0; j < datatmp.postdata.length; j++) {
                      //datatmp.postdata[i].id
                      //console.log(datatmp.postdata[i].id)
                      if (datatmp.postdata[j].id == actions[i].value.target){
                        ind_target = j
                      }
                      if (datatmp.postdata[j].id == actions[i].value.who){
                        ind_who = j
                      }
                  }

                  if (ind_who == ind_target)
                    break

                  let oldorder = datatmp.postdata[ind_who].order
                  let targetorder = datatmp.postdata[ind_target].order


                  console.log("found")
                  console.log(ind_who)
                  console.log(ind_target)


                  //console.log(datatmp.postdata[j].order)

                  console.log("TARGETORDER:")
                  console.log(targetorder)
                  console.log("OLDORDER:")
                  console.log(oldorder)


                  datatmp.postdata[ind_who].order = targetorder

                  if (oldorder < targetorder) {
                    datatmp.postdata[ind_target].order -= 10
                  } else {
                    datatmp.postdata[ind_target].order += 10
                  }

                  for (var k = 0; k < datatmp.postdata.length; k++) {

                    if (k == ind_who || k == ind_target) {
                      continue
                    }

                    let thorder = (k+1)*10
                    let thadd = 0
                    if (thorder < oldorder) {
                        thadd = thadd + 10
                    }
                    if (thorder > oldorder) {
                        thadd = thadd - 10
                    }
                    if (thorder < targetorder) {
                        thadd = thadd - 10
                    }
                    if (thorder > targetorder) {
                        thadd = thadd + 10
                    }

                    datatmp.postdata[k].order = (k+1)*10 + thadd

                  }

                  if (0) {


                    console.log("found")
                    console.log(ind_who)
                    console.log(ind_target)


                    //console.log(datatmp.postdata[j].order)

                    console.log("TARGETORDER:")
                    console.log(targetorder)
                    console.log("OLDORDER:")
                    console.log(oldorder)

                    for (var k = 0; k < datatmp.postdata.length; k++) {
                        //console.log(datatmp.postdata[k].order)
                        if ((k+1)*10 < targetorder) {
                          datatmp.postdata[k].order = (k+1)*10
                          console.log("ASSIGN")
                          console.log((k+1)*10)
                          console.log(k)
                          console.log("................")
                        }
                        if ((k+1)*10 == targetorder) {
                          if (datatmp.postdata[ind_who].order >= targetorder) {
                            datatmp.postdata[k].order = targetorder - 10
                            console.log("ASSIGN")
                            console.log(targetorder - 10)
                            console.log(k)
                            console.log("________________________")
                          } else {
                            datatmp.postdata[k].order = targetorder + 10
                            console.log("ASSIGN")
                            console.log(targetorder + 10)
                            console.log(k)
                            console.log("________________________")
                          }

                        }
                        if ((k+1)*10 > targetorder) {
                          datatmp.postdata[k].order = (k+1)*10+10
                          console.log("ASSIGN")
                          console.log((k+1)*10+10)
                          console.log(k)
                          console.log("+++++++++++++++++++++++")
                        }

                        if (datatmp.postdata[k].id == actions[i].value.who){

                            datatmp.postdata[k].order = targetorder
                            console.log("target order specificially set")

                            console.log("ASSIGN")
                            console.log(targetorder)
                            console.log(k)
                            console.log("#######################")
                            //console.log(datatmp.postdata[k].order)
                        }

                        //datatmp.postdata[k].order = 99
                        //datatmp.postdata[k].order = (k+1)*10

                    }
                  }
                  if (actions[i].value.update_db_only) {
                    doupdate_dbonly = 1
                    doupdate = 0
                  } else {
                      doupdate = 1
                  }

                  break;

                default:
                    console.log("UNKNOWN ACTION")
            }

        }

        if (doupdate) {
            // update DB

            this.dbPost(this.sortPosts (datatmp))

            // new data
            this.setState({ data: datatmp })
            //this.sortPosts(this.state.data)
            this.filterPosts(datatmp.postdata)
            this.updateTags(datatmp)
        } else {
          if (doupdate_dbonly) {

            // new data
            this.setState({ data: datatmp })
            this.filterPosts(datatmp.postdata)
            this.updateTags(datatmp)
          }
        }
        // all actions complete
        this.props.onActionsComplete()
    }

    // functions fetches current data from server and calls callback after set state
    // todo controleler for signal to abort fetch when interval is running
    async updateData() {

        //if (this.props.conf.api_interval || !this.data) {
        let temp = await this.dbGet()

        this.setState({ data: temp })
        //}

        if (temp) {
            this.updateTags(temp)
            this.sortPosts(temp)
            this.filterPosts(temp.postdata)
        }

        if (this.props.conf.api_interval) {
            this.apiTimeout = setTimeout(this.updateData.bind(this), this.props.conf.api_interval);
        }
        else {
            clearTimeout(this.apiTimeout);
        }

    }

    updateTags() {

        //console.log("UPDATE TAGS SUGS")

        let tags = Array(this.state.data.postdata.map((sic, si) => {
            if (sic.tags.length) {
                return sic.tags.map((tic, ti) => {
                    if (tic.name) {
                        let val = tic.name.split(":")
                        if (val.length == 2)
                            return tic.name
                        return
                    }
                    else
                        return
                })
            } else {
                return
            }

        })).flat().flat().filter((retval) => retval != undefined)

        this.tags = tags.map((c, i) => {
            return { name: c }
        })

        this.tags = this.tags.filter((arr, index, self) =>
            index === self.findIndex((t) => (t.name === arr.name)))

        let temptagsar = []
        for (var i = 0; i < this.tags.length; i++) {
            //if (!files[i].name) return
            //fileList.push(files[i].name)
            let temp = this.tags[i].name.split(":")

            if (!(temp[0] in temptagsar))
                temptagsar[temp[0]] = []

            if ((temptagsar[temp[0]].indexOf(temp[1]) < 0))
                temptagsar[temp[0]] = [...temptagsar[temp[0]], temp[1]]
        }

        this.setState({ tagsar: temptagsar, tags: this.tags })
    }

    sortPosts (data) {

      //if (data.postdata) {
        let data_sorted = []

      console.log("SORT HERE HSORTE HERER SORHTERE ")
      console.log("SORT HERE HSORTE HERER SORHTERE ")
      console.log(data)

      data_sorted = data.postdata.sort((a, b) => (a.order > b.order) ? 1 : -1)
      data.postdata = data_sorted

        //data_sorted = data
        //console.log(data_sorted)

        console.log("SORT DONE ")

        this.setState({ data: data })
        return data
      //}
    }

    filterPosts(data) {

        let data_filtered = []

        //const { name, ...rest } = myObj;
        //console.log(name)
        //const { name: { name } } = this.state.data;
        //const { meta, name: { name2 } } = this.state.data;

        for (var i = 0; i < data.length; i++) {
            let test1 = data[i]
            let hit = 0
            //let test2 = Object.entries(this.state.data[i].tags).flat().flat()

            for (var j = 0; j < test1.tags.length; j++) {

                if (test1.tags[j].name && this.props.filters.includes(test1.tags[j].name)) {
                    hit += 1
                }
            }

            //if (hit) // like that its OR
            if (hit == this.props.filters.length) // like that its AND
                data_filtered.push(this.state.data.postdata[i])

        }

        //this.setState({ data_filtered: data_filtered.reverse() }, (() => {
        this.setState({ data_filtered: data_filtered }, (() => {
            this.props.onDataChange(this.state)
        }
        ))
    }




    dbGet() {

        console.log("DB FETCH")
        return fetch(this.props.conf.data_url + "?" + new Date().getMilliseconds())
            .then((data) => {

                if (data.status == 200) {

                } else {
                    console.log("RESPONSE bad")
                }

                return data.json()

            }, (error) => {
                if (error) {
                    // handle error here
                    //alert(error)
                }
            })
            ;
    }

    dbGetLogin() {

        console.log("DB LOGIN")
        return fetch(
            this.props.conf.data3_url + "?" + new Date().getMilliseconds(),
            {
                credentials: 'include',
            }

        )
            .then((data) => {

                if (data.status == 200) {

                } else {
                    console.log("RESPONSE bad")
                }

                return data.json()

            }, (error) => {
                if (error) {
                    // handle error here
                    //alert(error)
                }
            })
            ;
    }

    dbPost(thedata) {


        const theparams = JSON.stringify(thedata)


        var formData = new FormData();
        //formData.append('testdata', JSON.stringify(testdata));
        formData.append('thedata', JSON.stringify(thedata));

        //const encodedString = theparams.toString('base64');
        //console.log("encodedString")
        //console.log(this.props.script_url + "?json_content=" + encodeURIComponent(theparams))
        //return fetch(this.props.script_url + "?json_content=" + encodeURIComponent(theparams), {
        return fetch(this.props.conf.script_url, {
            method: "POST",
            mode: 'cors',
            credentials: 'include',
            //headers: {
            //'Accept': 'application/json',
            //    'Content-Type': 'application/json',
            //"Content-Type": "text/html",
            //},
            //body: JSON.stringify(testdata) //.toString('base64')
            body: formData
            //data: {foo: 'bar', bar: 'foo'},
        })
            .then(
                (response) => {
                    console.log("----------")
                    if (response.status == 200) {
                        // Process the response and update the view.
                        // Recreate a setTimeout API call which will be fired after 1 second.
                        console.log("db update ok.")
                    } else {
                        console.log(response.status)
                        alert("a no update could be sent to server.")
                    }

                    const thedata2 = response.json()
                    //console.log(thedata2)
                    return thedata2
                }, (error) => {
                    if (error) {
                        // handle error here
                        alert(error)
                        //console.log("ERROR")
                        //console.log(error)
                    }
                }
            )
            .then((data) => {
                //console.log("dbPost")
                //console.log(data)
                return data
            });
    }

    render() {
        return this.props.children
    }

}
