import http from "./http-common";

import configData from "../../configData.json";

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();
    //onUploadProgress = 20;
    formData.append("file", file);
    formData.append("filename", file);

    return http.post(configData.data2_url, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      //onUploadProgress      
    });
  }

  // getFiles() {

  //   let formData = new FormData();
  //   return http.post(configData.data2_url, formData,
  //     {
  //       withCredentials: true,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },

  //     },

  //   );
  //   //return http.get(configData.data2_url, {
  //   //  withCredentials: true,
  //   //});
  // }
}

export default new UploadFilesService();