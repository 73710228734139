import React from "react"

export default class InfoPage extends React.Component {

    constructor(props) {

        super(props)

        this.props = props
    }

    render() {

        console.log("Info Page")
        return (

            <main className="info">
                <div className="info-big">
                    {this.props.children}
                    

                    <svg className="svg">
                        <circle  className="path" cx="1em" cy="1em" r="1em" />
                    </svg>

                </div>
            </main>

        )
    }
}