import React, { Component } from "react";
import Dropzone from "react-dropzone";

import UploadService from "./services/upload-files.service";

export default class UploadFiles extends Component {

  constructor(props) {

    super(props);
    this.upload = this.upload.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.props = props

    this.state = {
      selectedFiles: undefined,
      status: [],
    };
  }

  componentDidMount() {
    // UploadService.getFiles().then((response) => {
    //   this.setState({
    //     fileInfos: response.data,
    //   });
    // });
  }

  upload() {

    this.state.selectedFiles.map((file, index) => {

      let currentFile = this.state.selectedFiles[index];

      let a = this.state.status.slice() //creates the clone of the state
      a[index].prog = 0
      a[index].status = "run"

      this.setState({
        status: a,
        currentFile: currentFile,
      });

      UploadService.upload(currentFile, (event) => {

        let a = this.state.status.slice() //creates the clone of the state
        a[index].prog = Math.round((100 * event.loaded) / event.total)

        this.setState({
          status: a,
        });
      })
        .then((response) => {
          console.log(response)

          let a = this.state.status.slice() //creates the clone of the state

          if (!response.data.message) {
            console.log(response.data.message)
            a[index].message = "Komplett. In der Galerie sichtbar. " + response.data.fnn
            console.log("abc")
            this.props.onUpload(response.data.fnn)
            console.log("abc")
            a[index].status = "complete"
            console.log("abc")
          }
          else {
            a[index].message = response.data.message
            a[index].status = "incomplete"
            console.log("abc")
          }
          console.log("abc")

          this.setState({
            status: a,
          });
          console.log("abc")

          //return UploadService.getFiles();
          return response.data.fnn
        })
        .then((files) => {
          console.log("upload fin init.")
          let a = this.state.status.slice() //creates the clone of the state
          a[index].fileInfos = files.data

          this.setState({
            status: a,
          });

          console.log("upload fin.")
          //this.setState({
          //  selectedFiles: undefined,
          //});
        })
        .catch(() => {

          console.log("upload err.")

          let a = this.state.status.slice() //creates the clone of the state
          a[index].prog = 0
          a[index].message = "Could not upload the file!"

          this.setState({
            status: a,
          });
        });



    })


  }

  onDrop(files) {
    if (files.length > 0) {

      let filesStatus = []

      for (let f of files) {
        filesStatus.push({
          status: "queued",
          progress: 0,
          message: "",
          fileInfos: [],
        })
      }

      this.setState({ selectedFiles: files, status: filesStatus });
    }
  }

  render() {
    const { selectedFiles, currentFile, progress, message, fileInfos, status } = this.state;

    let check_queued = status.filter((element) => {
      return element.status === "queued";
    })

    return (
      <div className="px-1 py-1">

        <Dropzone onDrop={this.onDrop} multiple={true}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: "dropzone border-5 border-dashed px-2 py-2 my-2" })}>
                <input {...getInputProps()} />
                Datei(en) hier droppen oder klicken.<br />

                <div className="selected-file">
                  {
                    //selectedFiles[0].name
                  }

                  {status && status.map((s, index) => (
                    <div className="" key={index}>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item border-1 rounded" key={index}>
                          {selectedFiles[index].name}
                          {s.status != "queued" && (
                            <div>
                              <div className="progress mb-3">
                                <div
                                  className="progress-bar progress-bar-info progress-bar-striped"
                                  role="progressbar"
                                  aria-valuenow={s.prog}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: s.prog + "%" }}
                                >
                                  {s.prog}%
                                </div>
                              </div>
                              <div className="text-xs">{s.message}</div>
                            </div>
                          )}
                        </li>


                      </ul>
                    </div>
                  ))}

                  {/* <ul className="list-group list-group-flush">
                      {selectedFiles.map((file, index) => (
                        <li className="list-group-item" key={index}>
                          {file.name}
                        </li>
                      ))}
                    </ul> */}
                </div>

              </div>
              <aside className="selected-file-wrapper">
                <button
                  className="btn btn-success"
                  disabled={!check_queued.length}
                  onClick={this.upload}
                >
                  Upload
                </button>
              </aside>
            </section>
          )}
        </Dropzone>

        {/* <div className="alert alert-light" role="alert">asd
          {message}
        </div> */}

        {/* {
          fileInfos.length > 0 && (
            <div className="card">
              <div className="card-header">List of Files</div>
              <ul className="list-group list-group-flush">
                {fileInfos.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    <a href={file.url}>{file.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          )
        } */}
      </div >
    );
  }
}