import axios from "axios";

//axios.defaults.withCredentials = true

export default axios.create({ 
  withCredentials: true,
  headers: {
    "Content-type": "application/json" 
  }
//  mode: 'no-cors',
});