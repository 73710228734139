
import React, { Component, useState, useEffect } from "react"

class Draggable extends React.Component {

  constructor(props) {
      super(props);
      // create a ref to store the textInput DOM element

      this.props = props

      this.state = {
          pos: {x: 0, y: 0},
          dragging: false,
          dragging_possible: false,
          pos_x: 0,
          pos_y: 0,
          rel: null
      };

      this.ref_container = React.createRef(null)

      this.onMouseMove = this.onMouseMove.bind(this);
      this.onMouseUp = this.onMouseUp.bind(this);

  }

  componentWillUnmount(props, state) {

    //console.log("COMPUNENT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT UNMOUINT ")

    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  componentDidUpdate(props, state) {

    if (this.state.dragging && !state.dragging || this.state.dragging_possible && !state.dragging_possible) {

      console.log("COMPUNENT UPGRADE 1")
      //this.props.ref_posts.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)
    } else if (!this.state.dragging && state.dragging) {

      console.log("COMPUNENT UPGRADE 2")

      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.onMouseUp)
    }
  }

    // calculate relative position to the mouse and set dragging=true
    onMouseDown (e) {

      console.log("Mouse down")

      // only left mouse button
      if (e.button !== 0) return
      //var pos = $(this.getDOMNode()).offset()
      //var computedStyle = window.getComputedStyle(this.getDOMNode());
      var computedStyle = window.getComputedStyle(this.ref_container.current);
      var pos = { top: parseInt(computedStyle.top), left: parseInt(computedStyle.left), width: parseInt(computedStyle.width), height: parseInt(computedStyle.height) };
      var pos_x = pos["left"]+pos["width"]/2
      var pos_y = pos["top"]+pos["height"]/2

      this.setState({
        dragging: false,
        dragging_possible: true,
        rel: {
          //x: e.pageX - pos.left,
          x: e.pageX,
          //y: e.pageY - pos.top
          y: e.pageY
        },
        pos_x: e.pageX,
        pos_y: e.pageY,
        el: this.props.akey
      })

      e.stopPropagation()
      e.preventDefault()
    }

    onMouseUp (e) {

      //console.log("THTS IT")
      //console.log(this.state.rel)

      if (this.state.dragging) {

        //console.log("THTS IT CHILDREN")
        //console.log(this.props.ref_posts.props.children)
        //console.log()
        var temp = this.props.ref_posts.current.children

        for (var i = 0; i < temp.length; i++) {

          var xref= temp[i].getBoundingClientRect().x+temp[i].getBoundingClientRect().width/2+window.scrollX
          var yref= temp[i].getBoundingClientRect().y+temp[i].getBoundingClientRect().height/2+window.scrollY
          //console.log("XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF XY-REF ")
          //console.log(xref)
          //console.log(yref)

          var d_x = this.state.rel.x - xref
          var d_y = this.state.rel.y - yref

          var dabs = Math.sqrt(d_x*d_x+d_y*d_y)
          var dabs_res
          var res_i

          if (i == 0) {
            dabs_res = dabs
            res_i = 0
          }
          if (dabs < dabs_res) {
            dabs_res = dabs
            res_i = i
          }


          //console.log (temp[i].getBoundingClientRect())
            //if (!files[i].name) return
            //fileList.push(files[i].name)
            //let temp = this.tags[i].name.split(":")

            //if (!(temp[0] in temptagsar))
            //    temptagsar[temp[0]] = []

            //if ((temptagsar[temp[0]].indexOf(temp[1]) < 0))
            //    temptagsar[temp[0]] = [...temptagsar[temp[0]], temp[1]]
        }


        console.log("CLOSEST ELEMENTS")
        console.log(temp[res_i].getAttribute('akey'))
        //console.log(this.state.el)

        this.props.onAction("ordermodify", { who: this.state.el, target: temp[res_i].getAttribute('akey') } )


        // actually do it
        //console.log(this.db)

        //e.stopPropagation()
        //e.preventDefault()


      }

      this.setState({ dragging: false, dragging_possible: false }, (() => {
          console.log(this.state.rel)
      }))


    }

    onMouseMove (e) {
      console.log("MOISE MOVE")

      if (!this.state.dragging && !this.state.dragging_possible) return

      //this.setState({
      //  pos: {
      //    x: e.pageX - this.state.rel.x,
      //    y: e.pageY - this.state.rel.y
      //  }
      //})
      if (Math.abs(this.state.pos_x - e.pageX) > 50 || Math.abs(this.state.pos_y - e.pageY) > 50) {
        console.log ("MOVED MORE THAN 50 PX in X OR Y")
        this.setState({
          dragging: true,
          rel: {
            //x: e.pageX - pos.left,
            x: e.pageX,
            //y: e.pageY - pos.top
            y: e.pageY
          }})
      } else {
        this.setState({
          dragging: false,
          })
      }



      //console.log("MOUSE INFO")
      console.log(e.pageX)
      console.log(e.pageY)

      // var temp = this.props.ref_posts.current.children

      // for (var i = 0; i < temp.length; i++) {
      //
      //   var xref= temp[i].getBoundingClientRect().x
      //   var yref= temp[i].getBoundingClientRect().y
      //
      //   var d_x = this.state.rel.x - xref
      //   var d_y = this.state.rel.y - yref
      //
      //   var dabs = Math.sqrt(d_x*d_x+d_y*d_y)
      //   var dabs_res
      //   var res_i
      //
      //   if (i == 0) {
      //     dabs_res = dabs
      //     res_i = 0
      //   }
      //   if (dabs < dabs_res) {
      //     dabs_res = dabs
      //     res_i = i
      //   }


        //console.log (temp[i].getBoundingClientRect())
          //if (!files[i].name) return
          //fileList.push(files[i].name)
          //let temp = this.tags[i].name.split(":")

          //if (!(temp[0] in temptagsar))
          //    temptagsar[temp[0]] = []

          //if ((temptagsar[temp[0]].indexOf(temp[1]) < 0))
          //    temptagsar[temp[0]] = [...temptagsar[temp[0]], temp[1]]
      // }


      //this.props.onAction("ordermodify", { update_db_only : 1, who: this.state.el, target: temp[res_i].getAttribute('akey') } )

      e.stopPropagation()
      e.preventDefault()
    }

  render () {

    // transferPropsTo will merge style & other props passed into our
    // component to also be on the child DIV.
    return (
      <div akey={this.props.akey} ref={this.ref_container} onMouseDown={this.onMouseDown.bind(this)} className={ "" + ( this.props.className || "") }>{this.props.children}</div>
    )

  }
}

export default Draggable
