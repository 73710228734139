import React from "react"
import { config } from "react-transition-group";
//import samplepic from "../samplepics/pineapple.jpg"
import InfoPage from "./InfoPage"

export default class Imprint extends React.Component {

    constructor(props) {

        super(props);

        this.props = props;

    }

    componentDidMount() {

        this.props.onNavUpdate("/imprint")
    }


    render() {


        //if (!this.props.thedata) return <InfoPage> Inhalte werden geladen ...</InfoPage>;

        return (

            <div className="databox">
              <h1>Impressum</h1>
              <p>Jasmin Nussbaum<br />
              DIEHESS<br />
              Entenbachstrasse 42<br />
              81541 M&uuml;nchen</p>
              <h2>Kontakt</h2>
              <p>Telefon: +4915125992876<br />
              E-Mail: info@diehess.com</p>
              <h2>Umsatzsteuer-ID</h2>
              <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
              DE340539071</p>
              <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
              <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.</p>
              <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-
              generator.html</a></p>
            </div>
        )
    }
}
