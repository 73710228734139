import { BrowserRouter, Route, Routes, useParams, useLocation } from "react-router-dom"

import Home from "./components/Home"
import About from "./components/About"
import Datasafety from "./components/Datasafety"
import Imprint from "./components/Imprint"
import Admin from "./components/Admin"
import Posts from "./components/Posts"
import Post from "./components/Post"
//import PostCreate from "./components/PostCreate"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"

import React from 'react';

import configData from "./configData.json";

import TheDb from "./components/services/db";
import InfoPage from "./components/InfoPage"

class App extends React.Component {


  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element

    if (window.location.href.includes("192.168.1") || window.location.href.includes("localhost") || window.location.href.includes("jsm3.routerich.com")) {
      configData.data_url = configData.data_url_dev
      configData.images_url = configData.images_url_dev
    } else {
      //configData.images_url = configData.images_url
      if (window.location.href.includes("diejasmin.de")) {
        configData.images_url = configData.images_url2
        configData.data_url = configData.data_url2
      }
    }
    //configData.images_url = configData.images_url2
    //configData.data_url = configData.data_url2

    this.db_conf = { api_interval: configData.api_interval, data_url: configData.data_url, data3_url: configData.data3_url, script_url: configData.script_url };
    this.state = {
      db_data: false,
      filters: [],
      actions: [],
      st_nav: "/",
      is_admin: false
    };

    this.ref_main = React.createRef(null)
    this.ref_header = React.createRef(null)
    this.ref_test = React.createRef(null)

    this.testvar = ""
    //this.props = props
    //this.state = { data: [], data_filtered:[], filter: false };
    //this.tagsar = []
    //this.filters = []

  }

  db_changed(dbarr) {

    console.log("DB CHANGED")
    this.setState({ db_data: dbarr })
  }

  db_filter_toggle(e, key) {

    console.log("FILTER TOGGLE")

    e.preventDefault()
    console.log(key)

    let filtertemp = [...this.state.filters];
    let fidx = filtertemp.findIndex(element => element == key)
    if (fidx == -1) {
      filtertemp = [...filtertemp, key]
    } else {

      filtertemp.splice(fidx, 1);
    }

    this.setState({ filters: filtertemp })
  }

  db_actions_complete() {
    this.setState({ actions: [] })
  }

  db_add_action(name, value) {

    console.log("DB ADD ACTION")
    console.log(name)
    console.log(value)

    let actionstemp = [...this.state.actions];
    //actionstemp = [...actionstemp, {["action_" + (name)] : value}, ];
    actionstemp = [...actionstemp, { value: value, action: name },];
    this.setState({ actions: actionstemp })
  }

  componentDidMount() {
    console.log("MAIN MOUNT")
    //document.title = "Jasmin Hess"
  }


  componentDidUpdate() {
    console.log("MAIN UPDATE")
  }

  navUpdate(newnav) {
    console.log("NAV UPDATE")

    if (this.state.st_nav != newnav)
      this.setState({ st_nav: newnav })
  }

  adminToggle(e) {

    e.preventDefault()

    if (this.state.is_admin) {
      this.setState({
        is_admin: !this.state.is_admin
      })
    } else {
      this.db_add_action("login", "true")
    }
  }

  db_login(val) {
    if (val) {
      this.setState({
        is_admin: true
      })
    }
  }

  // adminUpdate (newstate) {
  //   this.setState({
  //     is_admin: newstate
  //   })
  // }

  // https://codeburst.io/deploying-and-hosting-a-react-app-on-an-apache-server-b7121dffb07a

  render() {

    const GetRenderId = (props) => {

      const { theid } = useParams()

      let retval = null;

      switch (props.type) {

        case "/post/edit":
          retval = <Post onAction={this.db_add_action.bind(this)} state_admin={this.state.is_admin} edit={true} theid={theid} thedata={this.state.db_data} imgurl={configData.images_url} onNavUpdate={this.navUpdate.bind(this)} />
          break;
        case "/post/create":
          retval = <Post onAction={this.db_add_action.bind(this)} state_admin={this.state.is_admin} edit={true} isnew={true} theid={theid} thedata={this.state.db_data} imgurl={configData.images_url} onNavUpdate={this.navUpdate.bind(this)} />
          break;
        case "/post":
          retval = <Post onAction={this.db_add_action.bind(this)} state_admin={this.state.is_admin} edit={false} theid={theid} thedata={this.state.db_data} imgurl={configData.images_url} onNavUpdate={this.navUpdate.bind(this)} />
          break;
        case "/imprint":
            retval = <Imprint thedata={this.state.db_data} imgurl={configData.images_url} onNavUpdate={this.navUpdate.bind(this)} />
          break;
        case "/datasafety":
            retval = <Datasafety thedata={this.state.db_data} imgurl={configData.images_url} onNavUpdate={this.navUpdate.bind(this)} />
            break;
        case "/about":
          retval = <About thedata={this.state.db_data} imgurl={configData.images_url} onNavUpdate={this.navUpdate.bind(this)} />
          break;
        case "/admin":
          retval = <Admin onNavUpdate={this.navUpdate.bind(this)} onAction={this.db_add_action.bind(this)} />
          break;
        case "/":
          retval = <Posts state_admin={this.state.is_admin} thedata={this.state.db_data} onAction={this.db_add_action.bind(this)} onNavUpdate={this.navUpdate.bind(this)} />
          break;
        case "error":
          retval = <InfoPage onNavUpdate={this.navUpdate.bind(this)}>404<br /> Seite nicht gefunden</InfoPage>
          break;
        default:
          break;
      }

      if (!retval)
        retval = <InfoPage>500<br /> SYSTEM ERROR.</InfoPage>

      this.testvar = props.type
      //console.log(props.type)
      //this.setState({ st_nav: props.type })

      return retval
    }

    const Test1 = () => <h1>Test111</h1>;

    //function App() {
    //const App = () => {
    return (
      <div>
        <TheDb conf={this.db_conf} onActionsComplete={this.db_actions_complete.bind(this)} actions={this.state.actions} filters={this.state.filters} onDataChange={this.db_changed.bind(this)} onLogin={this.db_login.bind(this)}>
          <BrowserRouter>
            <header ref={this.ref_header} className='header'>
              <Navbar state_admin={this.state.is_admin} onAdminToggle={this.adminToggle.bind(this)} ref_content={this.ref_main} ref_header={this.ref_header} thedata={this.state.db_data} filters={this.state.filters} onFilter={this.db_filter_toggle.bind(this)} currentLocation={this.state.st_nav} />
            </header>
            <main ref={this.ref_main} className="main-content">

              <Routes>


                {/* <Route path="users/*" element={<Users />} /> */}

                {/* <Route path="/" exact element={<Posts data_filtered={this.state.data_filtered}/>} /> */}
                {/* <Route path="/post/modify/:theid" element={<GetId />} /> */}
                {/* <Route path="/post/modify/:theid" element={<GetRender /> } /> */}
                {/* <Route path="/post/create" element={<PostCreate />} /> */}
                {/* <Route path="/post/modify/:id" render={ ({match}) => <PostCreate id={match.params.id}/>} /> */}

                {/* <Route
  path='/test123'
  render={(props) => <About />} */}
                {/* /> */}
                {/* <Route path="/post/:theid" element={<Post modify="false" thedata={this.state.db_data} />} />                   */}
                <Route path="/post/:theid"
                  element={<GetRenderId type="/post" />}
                />
                <Route path="/post/edit/:theid"
                  element={<GetRenderId type="/post/edit" />}
                />
                <Route path="/post/create"
                  element={<GetRenderId type="/post/create" />}
                />
                <Route path="/about"
                  element={<GetRenderId type="/about" />}
                />
                <Route path="/datasafety"
                  element={<GetRenderId type="/datasafety" />}
                />
                <Route path="/imprint"
                  element={<GetRenderId type="/imprint" />}
                />
                <Route path="/admin123"
                  element={<GetRenderId type="/admin" />}
                />
                <Route path="/" element={<GetRenderId type="/" />} />
                <Route path="*" element={<GetRenderId type="error" />} />

              </Routes>

            </main>
            <footer>
              <Footer />
            </footer>
          </BrowserRouter>
        </TheDb>
      </div>
    )
    // fgColor="#fff" style="{{height: 35, width: 35}}"

  }
}

export default App;


// https://www.youtube.com/watch?v=NO7_jgzVgbc
