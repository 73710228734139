import React, { Component, useState, useEffect } from "react"
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog"; import "@reach/dialog/styles.css";
//import { ajax } from 'rxjs/ajax';
import { ImNotification, ImQuestion } from "react-icons/im";

import configData from "../configData.json";

class ConfDialogue extends React.Component {

    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element

        this.confirm = this.confirm.bind(this);

        this.props = props

        this.state = {
            showDialog: false
        };

        //console.log("constructor init.")
    }

    confirm() {

        this.setState({ showDialog: true })
    }

    thedialogue() {

        return this.state.showDialog ? (

            <div className="mydialogue">
            <ImQuestion size="35" /> Best&auml;tigen
            <div className='question'>
            {this.props.text}
            </div>
            <button onClick={() => this.confirmYes()} className="bg-orange-400 hover:bg-orange-600 text-white font-bold py-4 px-8 mx-4 my-4 rounded">Ja</button>
            <button onClick={() => this.confirmNo()} className="bg-black hover:bg-gray-700 text-white font-bold py-4 px-8 mx-4 my-4 rounded">Nein</button>
            </div>

            //<Dialog aria-label={"del_" + this.props.action} style={{padding:0, position:"relative", zIndex:"55 !important"}} >
            //<div className="dialogue">
                //<div className="flex border-b-2 bg-gray-50">
                    //<div className="items-center py-3 px-3"><ImQuestion size="35" /></div><div className="items-center py-3 px-3 hover:text-green-800 text-2xl font-bold cursive tracking-widest">Bestätigen</div>
                //</div>
                //<div>
                    //<p className="mx-5 my-5 text-2l font-bold">
                        //{this.props.text}
                    //</p>
                    //<button onClick={() => this.confirmYes()} className="bg-orange-400 hover:bg-orange-600 text-white font-bold py-4 px-8 mx-4 my-4 rounded">Ja</button>
                    //<button onClick={() => this.confirmNo()} className="bg-black hover:bg-gray-700 text-white font-bold py-4 px-8 mx-4 my-4 rounded">Nein</button>
                //</div>
              //  </div>
            //</Dialog>

            ) : ""

    }

    confirmYes() {
        //console.log("sending")
        //console.log(this.props.action)

        this.props.onConfirm (this.props.action)
        //onConfirm

        // $.ajax({
        //     type: "GET",
        //     url: 'http://www.oxfordlearnersdictionaries.com/search/english/direct/',
        //     data:{q:idiom},
        //     async:true,
        //     dataType : 'jsonp',   //you may use jsonp for cross origin request
        //     crossDomain:true,
        //     success: function(data, status, xhr) {
        //       alert(xhr.getResponseHeader('Location'));
        //     }
        //   });

        //const response = fetch(this.props.action);
        //const temp = response.json();
        //console.log (temp)






        this.setState({ showDialog: false })
    }

    confirmNo() {
        this.setState({ showDialog: false })
    }

    render() {
        // tell React that we want to associate the <input> ref
        // with the `textInput` that we created in the constructor
        return (
            <div>
                <div onClick={this.confirm}>
                    {this.props.children}
                </div>
                <div>{this.thedialogue()}</div>
            </div>
        );
    }


    // constructor(props) {
    //     super(props);
    //     // create a ref to store the textInput DOM element
    //     this.textInput = React.createRef();
    //     this.focusTextInput = this.focusTextInput.bind(this);
    // }

    // focusTextInput() {
    //     // Explicitly focus the text input using the raw DOM API
    //     // Note: we're accessing "current" to get the DOM node
    //     this.textInput.current.focus();
    // }

    // render() {
    //     // tell React that we want to associate the <input> ref
    //     // with the `textInput` that we created in the constructor
    //     return (
    //         <div>
    //             <input
    //                 type="text"
    //                 ref={this.textInput}
    //             />
    //             <input
    //                 type="button"
    //                 value="Focus the text input"
    //                 onClick={this.focusTextInput}
    //             />
    //         </div>
    //     );
    // }
}

export default ConfDialogue
